<template>
  <div class="pr-3">
    <vs-card class="event-details">
      <div class="d-flex flex-row align-items-center justify-content-between font-weight-bold mb-3">
        <span
          style="font-size: 2rem;"
          :style="{ color: colorPrimary }"
        >
          Hello World
        </span>
      </div>
    </vs-card>
  </div>
</template>

<script>
export default {
  name: 'EventDetails',
  computed: {
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
  },
};
</script>

<style lang="scss">
.event-details {
  .vs-collapse {
    padding: 0;
  }
}
</style>
